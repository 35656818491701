import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "trustanbouw"
    }}>{`TrustanBouw`}</h1>
    <p><em parentName="p">{`Good to know: our system is referred to as BPO 2.0`}</em></p>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`TrustanBouw is a construction company that carries out various small and large renovations of homes for private individuals, property owners and catering companies on project basis. They have a unique proposition
in which they employee professionals from Slovakia, led by project managers and others from TrustanBouw. Due to legislation and the fact they employee foreigners from Slovakia,
they have seperated their (financial) administration into 3 entities, which can be seen in the following diagram:`}</p>
    <p><img alt="Alt text" src={require("./blob/administrations.png")} /></p>
    <p><strong parentName="p">{`SBFN`}</strong>{` (Stichting Bouw Facturatie Nederland)`}<br parentName="p"></br>{`
`}{`The umbrella entity under which both STAV and TBM belong.`}</p>
    <p><strong parentName="p">{`TBM`}</strong>{` (TrustanBouw Management)`}<br parentName="p"></br>{`
`}{`The entity in which all the work of TrustanBouw employees is administered.`}</p>
    <p><strong parentName="p">{`STAV`}</strong><br parentName="p"></br>{`
`}{`The entity in which all the work of Slovakian employees is administered.`}</p>
    <p>{`When a customer is invoiced, they will receive an invoice statement (borderel) which originates from `}<strong parentName="p">{`SBFN`}</strong>{`. On that sheet, a summary of the work, divided between
`}<strong parentName="p">{`TBM`}</strong>{` and `}<strong parentName="p">{`STAV`}</strong>{` is shown. The next sheet shows the `}<strong parentName="p">{`TBM`}</strong>{` details and the last sheet shows the `}<strong parentName="p">{`STAV`}</strong>{` details.`}</p>
    <p>{`The administration of data from our system to their financial system (CASH) is spread across these 3 entities, but not all data goes into all entities. Data is spread as followed:`}</p>
    <h4 {...{
      "id": "sbfn"
    }}>{`SBFN`}</h4>
    <ul>
      <li parentName="ul">{`Customers`}</li>
      <li parentName="ul">{`Projects`}</li>
      <li parentName="ul">{`Invoice statements`}</li>
    </ul>
    <h4 {...{
      "id": "tbm"
    }}>{`TBM`}</h4>
    <ul>
      <li parentName="ul">{`Customers`}</li>
      <li parentName="ul">{`Projects`}</li>
      <li parentName="ul">{`Suppliers`}</li>
      <li parentName="ul">{`Employees (only those that work for TBM)`}</li>
      <li parentName="ul">{`Purchase invoices`}</li>
      <li parentName="ul">{`Sales invoices (only those ment for TBM)`}</li>
      <li parentName="ul">{`Workcodes`}</li>
      <li parentName="ul">{`Timesheets (only those from employees that work for TBM, including (dutch) external employees)`}</li>
    </ul>
    <h4 {...{
      "id": "stav"
    }}>{`STAV`}</h4>
    <ul>
      <li parentName="ul">{`Projects`}</li>
      <li parentName="ul">{`Employees (only those that work for STAV)`}</li>
      <li parentName="ul">{`Sales invoices (only those ment for STAV)`}</li>
      <li parentName="ul">{`Timesheets (only those from employees that work for STAV)`}</li>
    </ul>
    <p>{`The reason for these seperated administrations is due to legislation and the fact that the Slovakian employees need to pay taxes in Slovakia. This way, TrustanBouw can hand over
their STAV administration to the tax authorities in Slovakia with relative ease.`}</p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <p>{`The whole project is build with microservices, inspired by Domain Driven Design. Each microservice fills the need of a specific domain within TrustanBouw.`}</p>
    <p><img alt="Alt text" src={require("./blob/global-architecture-trustanbouw.png")} /></p>
    <h2 {...{
      "id": "required-tools"
    }}>{`Required tools`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Git`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Git is a free and open source distributed version control system designed to handle everything from small to very large projects with speed and efficiency.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://git-scm.com/"
            }}>{`https://git-scm.com/`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Git secret`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The repository contains several secrets (connection strings, passwords etc) They are encrypted with git secret. Make sure that you are added to the repository and have the ability to run `}<inlineCode parentName="td">{`git secret reveal`}</inlineCode>{`. You can see who is able to add you via `}<a parentName="td" {...{
              "href": "src-getting-started-gitsecret#show-added-people"
            }}>{`git secret whoknows`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "src-getting-started-gitsecret"
            }}>{`git-secret`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Docker and Docker Compose`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Docker is an open platform for developing, shipping, and running applications in containers.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.docker.com/products/docker-desktop/"
            }}>{`Docker Desktop`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`.NET CORE 6.0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(.NET CORE) C# is used for all the backend microservices`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://learn.microsoft.com/en-us/dotnet/core/introduction"
            }}>{`https://learn.microsoft.com/en-us/dotnet/core/introduction`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NODE LTS 16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NodeJS is used for front-end development`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://nodejs.org/en/blog/release/v16.16.0/"
            }}>{`https://nodejs.org/en/blog/release/v16.16.0/`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`yarn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yarn is used as our package manager`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://yarnpkg.com/"
            }}>{`https://yarnpkg.com/`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "source-code"
    }}>{`Source code`}</h2>
    <p>{`The TrustanBouw project is managed as a mono repo in Azure DevOps.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Repository`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TrustanBouw`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mono repo for the trustanbouw project`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://dev.azure.com/hybritdev/_git/TrustanBouw"
            }}>{`https://dev.azure.com/hybritdev/_git/TrustanBouw`}</a></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "mono-repository"
    }}>{`Mono repository`}</h3>
    <p>{`The fact that we use a mono repository, does not mean we have linked services and/or projects within your IDE. Any change made to either:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Trustanbouw.Portal.*.Service.Contracts`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Trustanbouw.Portal.Packages.Common`}</inlineCode>{` or `}<inlineCode parentName="li">{`Trustanbouw.Portal.Packages.Common.Contracts`}</inlineCode>{` (these are versioned the same)`}</li>
    </ul>
    <p>{`Changes to the code within these projects will need to be pushed and build/published by de CI/CD pipeline. We use `}<inlineCode parentName="p">{`semversioning`}</inlineCode>{` and branches will automatically
build up a pre-release version. The following diagram shows this flow:`}</p>
    <p><img alt="Alt text" src={require("./blob/nuget-flow.png")} /></p>
    <h2 {...{
      "id": "endpoints"
    }}>{`Endpoints`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Environment`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Endpoint`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LOCAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "http://localhost:3000"
            }}>{`http://localhost:3000`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gateway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LOCAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "http://localhost:30073"
            }}>{`http://localhost:30073`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Swagger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LOCAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "http://localhost:30073/swagger/index.html"
            }}>{`http://localhost:30073/swagger/index.html`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.tb.hybrit.dev"
            }}>{`https://test.tb.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gateway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.gateway.tb.hybrit.dev"
            }}>{`https://test.gateway.tb.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Swagger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.gateway.tb.hybrit.dev/swagger/index.html"
            }}>{`https://test.gateway.tb.hybrit.dev/swagger/index.html`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`STAGING`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://staging.tb.hybrit.dev"
            }}>{`https://staging.tb.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gateway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`STAGING`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://staging.gateway.tb.hybrit.dev"
            }}>{`https://staging.gateway.tb.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Swagger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`STAGING`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://staging.gateway.tb.hybrit.dev/swagger/index.html"
            }}>{`https://staging.gateway.tb.hybrit.dev/swagger/index.html`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PROD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://tb.hybrit.dev"
            }}>{`https://tb.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gateway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PROD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://gateway.tb.hybrit.dev"
            }}>{`https://gateway.tb.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Swagger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PROD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://gateway.tb.hybrit.dev/swagger/index.html"
            }}>{`https://gateway.tb.hybrit.dev/swagger/index.html`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cashweb api`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DOES NOT APPLY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.cashweb.nl/?api/3.0"
            }}>{`https://www.cashweb.nl/?api/3.0`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cashweb ui`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DOES NOT APPLY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.cashweb.nl/"
            }}>{`https://www.cashweb.nl/`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "microservices"
    }}>{`Microservices`}</h2>
    <h3 {...{
      "id": "microservice-communication"
    }}>{`Microservice communication`}</h3>
    <p>{`Microservices do not depend on each other, each microservice works as a seperate unit and each microservice is responsible for their data within its domain. This does mean however, that we need the ability to share data. Communication between microservices is done through events, which are send to an Azure Service Bus. The service bus works with a topic. We have one topic, on which all microservices share their events. Each microservice can subscribe to a particular event (or multiple) that it is interested in. We have one topic per environment, within the same Azure Service Bus.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`trustanbouw-portal-messages-test`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`trustanbouw-portal-messages-staging`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`trustanbouw-portal-messages-prod`}</inlineCode></li>
    </ul>
    <p>{`Each service contains a `}<inlineCode parentName="p">{`Trustanbouw.Portal.*.Service..Contracts`}</inlineCode>{` project. In this project you will find a folder called `}<inlineCode parentName="p">{`Events`}</inlineCode>{`. All possible events that that service can emit will be noted there.`}</p>
    <p><strong parentName="p">{` When developing with docker compose, we use rabbitMQ in a docker container. Configuration and setup for this is transient and does not require any changes from the developer to setup.`}</strong></p>
    <h3 {...{
      "id": "authentication-and-authorization"
    }}>{`Authentication and authorization`}</h3>
    <p>{`All communication from/to the portal is done through a gateway. This gateway enforces authentication. `}<strong parentName="p">{`The gateway enforces an audience set in the JWT bearer token, which
are setup in the `}<inlineCode parentName="strong">{`appsettings.json`}</inlineCode>{` config file (`}<inlineCode parentName="strong">{`ValidAudiences`}</inlineCode>{` key) and in the environment helm chart values.`}</strong>{`
Each service does implement `}<inlineCode parentName="p">{`.AddAuthentication()`}</inlineCode>{` when bootstrapping, but this is mainly done to ensure the DI container is properly hydrated with the right services. The service itself enforces authorization through the `}<inlineCode parentName="p">{`[Authorize]`}</inlineCode>{` attribute. An easy way to think of this is that the gateway enforces authentication and the services itself enforce authorization.`}</p>
    <p>{`The following roles are available:`}</p>
    <ul>
      <li parentName="ul">{`administrator`}</li>
      <li parentName="ul">{`backoffice`}</li>
      <li parentName="ul">{`projectmanager`}</li>
      <li parentName="ul">{`planning`}</li>
      <li parentName="ul">{`manager`}</li>
      <li parentName="ul">{`calculationandsales`}</li>
      <li parentName="ul">{`serviceandmaintenance`}</li>
    </ul>
    <p>{`The list of roles and their spective authorizations can be found on google drive:
`}<a parentName="p" {...{
        "href": "https://docs.google.com/spreadsheets/d/1R7djp28z4aD2UDeIjp2CXtRZ4knAmMU4"
      }}>{`authorization and roles sheet`}</a></p>
    <p>{`We use the Azure Active Directory of Trustanbouw. All clients, users, groups and roles are managed there. This way, we can ensure Single Sign-On whenever they access the portal
from their (domain joined) computers.`}</p>
    <h3 {...{
      "id": "trustanbouwportalapigateway"
    }}>{`Trustanbouw.Portal.ApiGateway`}</h3>
    <p>{`Responsible for all routing from portal/public domain to the services. The gateway also enforces authentication. We use `}<a parentName="p" {...{
        "href": "https://github.com/ThreeMammals/Ocelot"
      }}>{`Ocelot`}</a>{` as the gateway framework. Routing is done with configuration files which are loaded based on the `}<inlineCode parentName="p">{`ASPNETCORE_ENVIRONMENT`}</inlineCode>{` environment variable.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Environment`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ASPNETCORE_ENVIRONMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Config`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LOCAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Development`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/Config/docker/*`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Test`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/Config/kubernetes/test/*`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`STAGING`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Staging`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/Config/kubernetes/staging/*`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PROD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Production`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/Config/kubernetes/prod/*`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`We use 4 authentication scheme's:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Schema`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PincodeHeaderAuthenticationScheme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used when timesheets are sent to the timemanagement api from employees (this url is public)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SwaggerAuthScheme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used when accessing swagger documentation, only users with a valid account can see this`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ApiAuthScheme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used when accessing any services (this enforces JWT bearer tokens)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ApiKeyAuthScheme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used by a legacy excelsheet which accesses certain data through api calls`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "trustanbouwportalcrmservice"
    }}>{`Trustanbouw.Portal.CRM.Service`}</h3>
    <p>{`Responsible for the following domain entities:`}</p>
    <ul>
      <li parentName="ul">{`Projects`}</li>
      <li parentName="ul">{`Customers`}</li>
      <li parentName="ul">{`Employees`}</li>
      <li parentName="ul">{`Suppliers`}</li>
      <li parentName="ul">{`Houses`}</li>
      <li parentName="ul">{`Cars`}</li>
      <li parentName="ul">{`Material`}</li>
      <li parentName="ul">{`Tags`}</li>
    </ul>
    <h3 {...{
      "id": "trustanbouwportaltimemanagementservice"
    }}>{`Trustanbouw.Portal.Timemanagement.Service`}</h3>
    <p>{`Responsible for the following domain entities:`}</p>
    <ul>
      <li parentName="ul">{`Timesheets`}</li>
    </ul>
    <ol>
      <li parentName="ol">{`When creating a new Timesheet the user will receive an email with a link where they can input their hours for the chosen week. `}<br />{`
this happens in the `}<inlineCode parentName="li">{`TimesheetMailSenderService.cs`}</inlineCode></li>
      <li parentName="ol">{`User can fill in their hours by clicking on the link in their email, which will take them to their unique (public) url. `}<br /><ol parentName="li">
          <li parentName="ol"><img alt="img_2.png" src={require("./img_2.png")} /></li>
          <li parentName="ol">{`Here they can fill in the Project they worked on, the start/end time, their discipline, their hourly rate and a note. `}</li>
          <li parentName="ol">{`After filling in a line press `}<inlineCode parentName="li">{`+`}</inlineCode>{`, the `}<inlineCode parentName="li">{`TimeSheet`}</inlineCode>{` will be updated with the inputted data, this happens in the `}<inlineCode parentName="li">{`AnonymousTimesheetController.cs (SaveTimesheetEntryAsync)`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`By pressing the `}<inlineCode parentName="li">{`X`}</inlineCode>{`, previously saved data can be removed.`}</li>
          <li parentName="ol">{`After all data is filled in for the week, a user can press the green `}<inlineCode parentName="li">{`OK`}</inlineCode>{` button on the bottom left. `}<br />{` `}<inlineCode parentName="li">{`TimeSheet`}</inlineCode>{` status is then changed to `}<inlineCode parentName="li">{`Submitted`}</inlineCode>{` and can no longer be changed.`}</li>
        </ol></li>
      <li parentName="ol"><inlineCode parentName="li">{`TimeSheet`}</inlineCode>{` can then be checked by `}<inlineCode parentName="li">{`[planning, backoffice, projectlead, manager]`}</inlineCode>{` users, and sent for approval on the hour approval page.`}</li>
      <li parentName="ol"><img alt="img_3.png" src={require("./img_3.png")} /></li>
      <li parentName="ol"><inlineCode parentName="li">{`TimeSheet`}</inlineCode>{` can then be approved or disapproved by `}<inlineCode parentName="li">{`[planning, backoffice, projectlead, manager]`}</inlineCode>{` users, this happens in the `}<inlineCode parentName="li">{`TimesheetEntryApprovalController.cs`}</inlineCode><ol parentName="li">
          <li parentName="ol">{`In case of a re-calculation project the timesheet needs to be approved/disapproved twice, for the second time on the recalculate approval hours page.`}</li>
        </ol></li>
      <li parentName="ol">{`Once approved, the costs should be visible on the project overview`}</li>
    </ol>
    <h3 {...{
      "id": "trustanbouwportalpurchaseinvoicingservice"
    }}>{`Trustanbouw.Portal.PurchaseInvoicing.Service`}</h3>
    <p>{`Responsible for the following domain entities:`}</p>
    <ul>
      <li parentName="ul">{`Purchase invoices`}</li>
    </ul>
    <h3 {...{
      "id": "trustanbouwportalsalesinvoicingservice"
    }}>{`Trustanbouw.Portal.SalesInvoicing.Service`}</h3>
    <p>{` Responsible for the following domain entities:`}</p>
    <ul>
      <li parentName="ul">{`Invoice statements`}</li>
      <li parentName="ul">{`Sales invoices`}</li>
    </ul>
    <h3 {...{
      "id": "trustanbouwportalcostmanagementservice"
    }}>{`Trustanbouw.Portal.CostManagement.Service`}</h3>
    <p>{`Responsible for showing the cost overview on projects.`}</p>
    <h3 {...{
      "id": "trustanbouwportalcashintegrationservice"
    }}>{`Trustanbouw.Portal.CashIntegration.Service`}</h3>
    <p>{`Responsible for the integration to CASH.`}</p>
    <h3 {...{
      "id": "trustanbouwportaldataintegrityservice"
    }}>{`Trustanbouw.Portal.DataIntegrity.Service`}</h3>
    <p>{`Non-functional service which connects to CASH and databases from services to verify existence of data.`}</p>
    <h2 {...{
      "id": "portal-ui"
    }}>{`Portal (UI)`}</h2>
    <p>{`Single Page Application, found at `}<inlineCode parentName="p">{`trustanbouw-portal`}</inlineCode>{` in the mono repo. Currently two single page apps are served:`}</p>
    <ul>
      <li parentName="ul">{`main app (`}<inlineCode parentName="li">{`src/app`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`timesheet employee app (`}<inlineCode parentName="li">{`src/timesheet`}</inlineCode>{`)`}</li>
    </ul>
    <p>{`All front-end api calls are routed via /api/* endpoints using next api routes. This is done so to prevent any CORS issues and host everything on the same domain.`}</p>
    <h3 {...{
      "id": "coding--techniques"
    }}>{`Coding & techniques`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nextjs.org/"
        }}>{`next.js`}</a>{` is an open-source web development framework created by Vercel enabling React-based web applications with server-side rendering and generating static websites.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mui.com/"
        }}>{`mui`}</a>{` provides a simple, customizable, and accessible library of React components.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://v5.reactrouter.com/"
        }}>{`react-router@5`}</a>{` is a collection of navigational components that compose declaratively with your application.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://react-query.tanstack.com/"
        }}>{`react-query`}</a>{` is a powerful asynchronous state management, server-state utilities and data fetching for TS/JS, React, Solid, Svelte and Vue`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://babeljs.io/docs/en/babel-preset-typescript"
        }}>{`typescript`}</a>{` is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://orval.dev/"
        }}>{`orval`}</a>{` is used to generate typescript interfaces and http calls based the on api swagger definitions. Orval is configured to additionally generate react-query hooks for each endpoint. See orval.config.js for details.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://axios-http.com/docs/intro"
        }}>{`axios`}</a>{` is a promise-based HTTP Client for node.js and the browser. On the server-side it uses the native node.js http module, while on the client (browser) it uses XMLHttpRequests.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://date-fns.org/"
        }}>{`date-fns`}</a>{` is a toolset for manipulating JavaScript dates in a browser & Node.js`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/package/yup"
        }}>{`yup`}</a>{` is a JavaScript schema builder for value parsing and validation`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://react.i18next.com/"
        }}>{`react-i18next`}</a>{` react-i18next is used as an internationalization framework for the front-end. Only dutch is currently supported. See src/app/config/i18n.ts for details.`}</li>
    </ul>
    <h3 {...{
      "id": "start"
    }}>{`Start`}</h3>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`yarn install`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`yarn dev`}</inlineCode></li>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "http://localhost:3000"
        }}>{`http://localhost:3000`}</a></li>
    </ol>
    <h3 {...{
      "id": "using-orval"
    }}>{`Using orval`}</h3>
    <p>{`We use orval to generate all models and service calls based on the swagger documentation from the services. To update the generated code, you can follow these steps:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to `}<a parentName="p" {...{
            "href": "http://localhost:30073/swagger/index.html"
          }}>{`http://localhost:30073/swagger/index.html`}</a>{` (or use another environment)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select your swagger definition in the right upper corner`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Download the swagger json`}<br parentName="p"></br>{`
`}<img alt="Alt text" src={require("./blob/swagger-definition-step-1.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save the file to `}<inlineCode parentName="p">{`trustanbouw-portal/swagger-definitions`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<inlineCode parentName="p">{`yarn api:generate`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Your models and calls have been updated`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "cashweb-ui-cash"
    }}>{`Cashweb UI (CASH)`}</h2>
    <p><a parentName="p" {...{
        "href": "https://cashweb.nl/"
      }}>{`CASH`}</a>{` is used by Trustanbouw employees for accounting (boekhouden). This is where they manage their 3-way financial administration as described in the `}<a parentName="p" {...{
        "href": "#project-outline"
      }}>{`project outline`}</a>{` and it is their 'source of truth'. `}</p>
    <p><strong parentName="p">{`WE IDEALLY DO NOT NEED TO ACCESS CASH`}</strong>{`. The api of CASH is fickle and has its quirks. But Trustanbouw employees (with the correct permissions) `}<strong parentName="p">{`should be able to resolve most issues with CASH themselves`}</strong>{`. Most commonly, when a sync with CASH fails, they can clean up the respective entry themselves in CASH and then run the sync in our application again.`}</p>
    <p>{`We can however access CASH should we really need to.`}</p>
    <h3 {...{
      "id": "login-to-cash"
    }}>{`Login to CASH`}</h3>
    <p>{`Find the accountdata in LastPass. There's a chance not all fields are autocompleted, in which case you'll need to manually perform this action.`}</p>
    <p>{`When you've logged in, you'll be presented with a list of administration options. `}<strong parentName="p">{`tbma - Trustan Bouw Management B.V.`}</strong>{` is the administration synced to ours for almost everything.`}</p>
    <p><img alt="Alt text" src={require("./blob/cashweb/cashweb-select.png")} /></p>
    <h3 {...{
      "id": "using-cash"
    }}>{`Using CASH`}</h3>
    <p>{`It's important to know that:`}</p>
    <ul>
      <li parentName="ul">{`Cash doesn't allow two people to edit the data at the same time. Whenever you've opened one of the data windows (for example, `}<em parentName="li">{`Dagboeken > Inkomende facturen`}</em>{`), you are `}<strong parentName="li">{`locking others out of that window until you're done`}</strong>{`. When you're done, don't forget to hit `}<strong parentName="li">{`ESCAPE`}</strong>{` a number of times until you return to the Dashboard window.`}<ul parentName="li">
          <li parentName="ul">{`Opening another data window won't resolve this.`}</li>
        </ul></li>
      <li parentName="ul">{`BPO syncs to CASH. But vice versa, BPO only validates part of the data in CASH. It doesn't upsert if data has changed in CASH (which is intended). Hence, CASH is 'the source of truth', even though BPO is used to update the data.`}</li>
    </ul>
    <p>{`Hotkeys: some quick tips for navigating.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ENTER`}</strong>{` will allow you to move to the next line whenever you've selected something. It will also (immediately) execute with the data it has.`}</li>
      <li parentName="ul"><strong parentName="li">{`ESCAPE`}</strong>{` is the back / close button.`}</li>
    </ul>
    <h3 {...{
      "id": "operations"
    }}>{`Operations`}</h3>
    <p>{`Cashweb allow the viewing and modifying of data. Below are some of the common actions listed.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#finding-an-invoice"
        }}>{`Finding an invoice`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#creating-an-invoice"
        }}>{`Creating an invoice`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#clearing--re-syncing-an-invoice"
        }}>{`Clearing / re-syncing an invoice`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#finding-workcodes"
        }}>{`Finding workcodes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#finding-suppliers"
        }}>{`Finding suppliers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#finding-timesheets--worked-hours"
        }}>{`Finding timesheets / worked hours`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#clearing-data-other-than-invoices"
        }}>{`Clearing data other than invoices`}</a></li>
    </ul>
    <h4 {...{
      "id": "finding-an-invoice"
    }}>{`Finding an invoice`}</h4>
    <p>{`You can find invoices (boekstukken) in Cash by using the `}<strong parentName="p">{`journalNumber`}</strong>{`.`}</p>
    <p>{`In BPO, go to `}<em parentName="p">{`Data - Inkoopfacturen`}</em>{` and select the journalnumber (stuknummer) you wish to find. Copy it.`}</p>
    <p><img alt="Alt text" src={require("./blob/cashweb/cashweb-invoicefind-bpo.png")} /></p>
    <p>{`In Cash, go to `}<em parentName="p">{`Dagboeken > Inkomende facturen`}</em>{`. Press ENTER twice to move the cursor to "Stuknummer". Paste it, then press ENTER. Details should appear. If no data appears, the invoice does not exist in Cash. This generally is denoted in BPO with a red cross.`}</p>
    <p><img alt="Alt text" src={require("./blob/cashweb/cashweb-invoicefind-cash.png")} /></p>
    <h4 {...{
      "id": "creating-other-invoices"
    }}>{`Creating other invoices`}</h4>
    <p>{`You can create other invoices (overige facturen) at Projects
Select a project and scroll down to Other Invoices, here you can manage other invoices belonging to this project.`}</p>
    <p><img alt="Alt text" src={require("./blob/trustanbouw-projects-otherinvoices.png")} /></p>
    <p>{`You can choose between 2 types: "More/less work" (Meer/minder werk) or "Other" (Overig), fill in the required fields and click on "Add" (Toevoegen) to add the invoice. Don't forget to save the project when you're done.`}</p>
    <p><img alt="Alt text" src={require("./blob/trustanbouw-projects-otherinvoices-add.png")} /></p>
    <p>{`At this point, the added invoices can still be removed and the date can still be changed.`}</p>
    <p><img alt="Alt text" src={require("./blob/trustanbouw-projects-otherinvoices-edit-and-remove.png")} /></p>
    <p>{`Upon approval, this is no longer possible.`}</p>
    <p><img alt="Alt text" src={require("./blob/trustanbouw-projects-otherinvoices-approved.png")} /></p>
    <p>{`Approving, denying or mailing an invoice can be done at Sales Invoices (Verkoopfacturen)`}</p>
    <p><img alt="Alt text" src={require("./blob/trustanbouw-salesinvoices-mail-approve-or-deny.png")} /></p>
    <h4 {...{
      "id": "clearing--re-syncing-an-invoice"
    }}>{`Clearing / re-syncing an invoice`}</h4>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`⚠️ THIS IS A DESTRUCTIVE OPERATION. Cashweb does not support transactions; it doesn't correct itself, there's no reverting an operation and no history to fall back on.`}</strong></p>
    </blockquote>
    <p>{`Whenever you need to sync in BPO, you need to ensure that the entry in CASH is cleared (`}<em parentName="p">{`Controleer voordat u synchroniseert of de inkoopfactuur in CASH is opgeschoond!`}</em>{`). Clearing happens in CASH.`}</p>
    <p>{`Following the steps for finding an invoice, open the invoice in CASH and press `}<strong parentName="p">{`F5`}</strong>{` . You'll be asked if you want to clear this. When you click yes, it will be cleared. Then re-sync in BPO by clicking the red cross belonging to the journalnumber.`}</p>
    <p><img alt="Alt text" src={require("./blob/cashweb/cashweb-invoicefind-cash-clear.png")} /></p>
    <p>{`After re-syncing, the entry should appear once more in CASH. `}<strong parentName="p">{`If it doesn't immediately, try again`}</strong>{`. CASH doesn't always respond with a 200.`}</p>
    <h4 {...{
      "id": "finding-workcodes"
    }}>{`Finding workcodes`}</h4>
    <p>{`Workcodes (werksoortcodes) can be found under `}<em parentName="p">{`Projecten > Werksoortcodes`}</em>{`.`}</p>
    <p>{`Enter a workcode (example: 10001) and press ENTER. The data shown shouldn't be too different from the information available in BPO.`}</p>
    <h4 {...{
      "id": "finding-suppliers"
    }}>{`Finding suppliers`}</h4>
    <p>{`Suppliers (leveranciers) can be found under `}<em parentName="p">{`Relaties`}</em>{`.`}</p>
    <p>{`Enter their code (you can find said code in BPO with the invoice in `}<em parentName="p">{`Data - Inkoopfacturen`}</em>{` or by looking it up in `}<em parentName="p">{`Leveranciers`}</em>{`) and press ENTER. Again, the data should match BPO.`}</p>
    <h4 {...{
      "id": "finding-timesheets--worked-hours"
    }}>{`Finding timesheets / worked hours`}</h4>
    <p>{`Trustanbouw tracks timesheets of two organisations. They use the following data:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Employee`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Administration`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Dagboekcode`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Boekdatum format`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TBM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`tbma - Trustan Bouw Management B.V.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BPOUUR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`jjmmdd`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`STAV`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`stau - STAV SLOVAKIA k.s.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BPORES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`jjmmdd`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Select the correct administration.`}</li>
      <li parentName="ul">{`Navigate to `}<em parentName="li">{`Algemeen`}</em>{` (this allows you to open all the Dagboeken).`}</li>
      <li parentName="ul">{`Press ENTER, select the correct Dagboekcode.`}</li>
      <li parentName="ul">{`Press ENTER, enter the journalnumber you wish to find timesheets for.`}</li>
      <li parentName="ul">{`Press ENTER. Finally, enter the date in the correct format for the day you wish to check.`}</li>
    </ul>
    <p>{`If done correctly, you'll get a list of employees assigned to the project who've worked on that day (`}<em parentName="p">{`the screenshot below shows no hits but does show dagboekcode, stuknummer, etc.`}</em>{`).`}</p>
    <p><img alt="Alt text" src={require("./blob/cashweb/cashweb-timesheetfind.png")} /></p>
    <h4 {...{
      "id": "clearing-data-other-than-invoices"
    }}>{`Clearing data other than invoices`}</h4>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`⚠️ THIS IS A DESTRUCTIVE OPERATION`}</strong>{`.`}</p>
    </blockquote>
    <p>{`Unlike invoices, other items may already have been used by other entries in CASH. If you destroy a reference, it may have consequences.`}</p>
    <p>{`If you need to, however, it works almost the same as with invoices. Please double check any references before proceeding.`}</p>
    <h2 {...{
      "id": "faq"
    }}>{`FAQ`}</h2>
    <h3 {...{
      "id": "how-to-run-a-service-locally"
    }}>{`How to run a service locally`}</h3>
    <ol>
      <li parentName="ol">{`Firstly we need to start up Docker Desktop`}</li>
      <li parentName="ol">{`Then open the TrustanBouw project and open the folder "Docker Compose"`}</li>
      <li parentName="ol">{`Right click on "Docker-compose.rabbit.yml" and select "Run in Docker Desktop"`}</li>
      <li parentName="ol">{`Decide which service you would like to run, for example "Trustanbouw-portal-crm"`}</li>
      <li parentName="ol">{`Open src and then the ".Service" project and then open "appsettings"`}</li>
      <li parentName="ol">{`Replace the CrmContextConnection with:`}</li>
      <li parentName="ol">{`Server: trustanbouw-pgsql-weu.postgres.database.azure.com`}</li>
      <li parentName="ol">{`Database: trustanbouw-crm-service-test`}</li>
      <li parentName="ol">{`Port: 5432`}</li>
      <li parentName="ol">{`and serviceBusOptions need the following settings:
"ConnectionString": "localhost",
"OrchestratorType": "rabbitmq",
"TopicName": "trustanbouw-portal-messages",
"SubscriptionName": "crm"`}</li>
    </ol>
    <h3 {...{
      "id": "how-to-whitelist-someones-ip"
    }}>{`How to whitelist someone's IP`}</h3>
    <ol>
      <li parentName="ol">{`if you get the following error:`}</li>
    </ol>
    <p><img alt="img.png" src={require("./img.png")} /></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Go to Portal.Azure.Com and login with hybritadmin@trustanbouw.org
Select the following project: trustanbouw-pgsql-weu  - azure database for postgresql single server`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><img alt="img_1.png" src={require("./img_1.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`go to settings -> connection security`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`here you can add an IP adres -> Don't forget to Save after adding.`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "login-to-azure"
    }}>{`Login to azure`}</h3>
    <p>{`All services run in a kubernetes cluster on Azure. Trustanbouw has its own azure subscription. You can use the following steps to login to their environment:`}</p>
    <ol>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "https://portal.azure.com"
        }}>{`https://portal.azure.com`}</a></li>
      <li parentName="ol">{`Enter `}<inlineCode parentName="li">{`hybritadmin@trustanbouw.nl`}</inlineCode></li>
      <li parentName="ol">{`Open lastpass and search for `}<inlineCode parentName="li">{`hybritadmin@trustanbouw.nl`}</inlineCode></li>
      <li parentName="ol">{`Enter the password found in lastpass and continue`}</li>
      <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`I can't use Microsoft Authenticator`}</inlineCode><br parentName="li"></br>
        <img alt="Alt text" src={require("./blob/trustanbouw-login-step-1.png")} /></li>
      <li parentName="ol">{`Click on `}<inlineCode parentName="li">{`Enter code`}</inlineCode><br parentName="li"></br>
        <img alt="Alt text" src={require("./blob/trustanbouw-login-step-2.png")} />{` `}</li>
      <li parentName="ol">{`Copy the TOTP code and login`}<br parentName="li"></br>
        <img alt="Alt text" src={require("./blob/trustanbouw-login-step-3.png")} />{` `}</li>
    </ol>
    <p>{`As an alternative, you can also go to the vault entry in lastpass and copy the TOTP code from there. `}</p>
    <h3 {...{
      "id": "deleting-an-invoice-from-a-project"
    }}>{`Deleting an Invoice from a project.`}</h3>
    <ol>
      <li parentName="ol">{`go to the project to determine if the type of cost is Materials or Labor.`}</li>
      <li parentName="ol">{`Find the SalesInvoice by looking for the SalesInvoiceNumber provided by the customer.`}</li>
      <li parentName="ol">{`depending on the type of cost, for Materials, find the corrosponding PurchaseInvoice, for labor find the corrospoding TimeSheetEntry`}</li>
      <li parentName="ol">{`Remove the SalesInvoiceId/Number from the PurchaseInvoice or TimeSheetEntry.`}</li>
      <li parentName="ol">{`Do the same for CostManagement Service`}</li>
    </ol>
    <h3 {...{
      "id": "invoice-statement-borderel-displays-a-0-value"
    }}>{`Invoice statement (borderel) displays a 0 value`}</h3>
    <p>{`Sometimes in BPO an invoice statement can't be found, this causes it to display a value of 0.`}</p>
    <p>{`We can however still read the response from CASH by looking in the database at the InvoiceStatements table and reading the ExportResponse column.`}</p>
    <p>{`If it displays a 200 OK response, we can safely assume it has been correctly received, we could then, but only by request, try to resend it by clearing the ExportFailure and set the status to NotProcessed.`}</p>
    <p>{`Further information is found in the subject below.`}</p>
    <h3 {...{
      "id": "purchase-invoices-not-in-cash"
    }}>{`Purchase invoices not in CASH`}</h3>
    <p>{`The CASH system has its quirks. `}</p>
    <p>{`We sometimes receive a 200 OK but in reality the purchase invoice or other entity is not properly synced. We can re-sync resources
through the data integrity UI, found here:`}</p>
    <p><img alt="Alt text" src={require("./blob/data-integrity-options.png")} /></p>
    <p>{`Select `}<inlineCode parentName="p">{`Data - inkoopfacturen`}</inlineCode>{` and select the booking period. An overview of all purchase invoices in the system will be shown and also
if the purchase invoice exists in CASH. It also details the purchase invoices known to the system and in which service they are found. `}</p>
    <p>{`For each service, we use the following icons:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Green checkmark`}</em>{` - Exists in CASH (and in our system)`}</li>
      <li parentName="ul"><em parentName="li">{`Orange checkmark`}</em>{` - Same as green checkmark, but something is missing. Hover over the icon to view the details`}</li>
      <li parentName="ul"><em parentName="li">{`Orange circle icon`}</em>{` - Sync error. Hover over the icon to view the XML details`}</li>
      <li parentName="ul"><em parentName="li">{`Blue marked icon`}</em>{` - Error with syncing data`}</li>
      <li parentName="ul"><em parentName="li">{`Red cross`}</em>{` - Not found`}</li>
    </ul>
    <p>{`Whenever there is a `}<strong parentName="p">{`red cross`}</strong>{` in the column `}<em parentName="p">{`'CASH'`}</em>{`, it means the purchase invoice is not known in CASH.`}</p>
    <p>{`By clicking on the red cross, we can re-sync that specific purchase invoice. However, `}<strong parentName="p">{`PLEASE MAKE SURE THAT THE ENTRY IN CASH IS CLEANED UP BEFORE RE-SYNC, AS CASH DOES NOT SUPPORT TRANSACTIONS`}</strong>{`. Preferably, ask Trustanbouw employees to do this for you (by replying to their support ticket or calling them).`}</p>
    <p>{`Alternatively, access CASH (cashweb.nl) yourself and clear the entry with that particular
journalnumber (stuknummer). `}<a parentName="p" {...{
        "href": "#cashweb-ui-cash"
      }}>{`Read more here`}</a>{`.`}</p>
    <p><img alt="Alt text" src={require("./blob/data-integrity-purchaseinvoices.png")} /></p>
    <h3 {...{
      "id": "purchase-invoices-sync-error"
    }}>{`Purchase invoices sync error`}</h3>
    <p>{`Whenever there is a `}<em parentName="p">{`orange circle icon`}</em>{` (see the picture) it means there is a sync error.
You can see the error message when clicking on the icon.`}</p>
    <p><img alt="Alt text" src={require("./blob/trustanbouw-purchase-invoice-sync-error.png")} /></p>
    <h4 {...{
      "id": "supplier-not-known"
    }}>{`supplier not known`}</h4>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`ongeldige waarde 101=X`}</inlineCode></p>
    </blockquote>
    <p>{`Whenever this message shows, it means that the supplier is not known in the TBMA administration in CASH. `}</p>
    <p><img alt="Alt text" src={require("./blob/trustanbouw-supplier-not-known.png")} /></p>
    <p>{`Firstly, check on the `}<inlineCode parentName="p">{`Data - leveranciers`}</inlineCode>{` page if the supplier is present and/or synced correctly.
If not, re-sync the supplier first, then re-sync the purchase invoice.`}</p>
    <p>{`If this is not the case, then we need to re-sync the supplier to the administration by resetting the ExportedOn flag in the database.`}</p>
    <ol>
      <li parentName="ol">{`Connect to the cash api database (connectionstring can be found in the helm chart yaml)`}</li>
      <li parentName="ol">{`Open the `}<inlineCode parentName="li">{`Suppliers`}</inlineCode>{` table`}</li>
      <li parentName="ol">{`Lookup the supplier with the code shown in the error message (in the above case this would be `}<inlineCode parentName="li">{`241914`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Set the `}<inlineCode parentName="li">{`ExportedOn`}</inlineCode>{` to `}<inlineCode parentName="li">{`NULL`}</inlineCode>{` and, if need be, set `}<inlineCode parentName="li">{`ExportFailureId`}</inlineCode>{` to `}<inlineCode parentName="li">{`NULL`}</inlineCode>{` as well`}</li>
      <li parentName="ol">{`Save changes and wait for the system to re-sync the data (takes between 10-20 seconds)`}</li>
      <li parentName="ol">{`If the `}<inlineCode parentName="li">{`ExportedOn`}</inlineCode>{` flag is set and the `}<inlineCode parentName="li">{`ExportFailureId`}</inlineCode>{` is `}<inlineCode parentName="li">{`NULL`}</inlineCode>{` then the supplier is succesfully synced.`}</li>
    </ol>
    <h4 {...{
      "id": "supplier-must-contain-x-at-field-y"
    }}>{`supplier must contain X at field Y`}</h4>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`moet X @ bevatten 120=onbekend`}</inlineCode></p>
    </blockquote>
    <p>{`This error or any comparable error usually means that the supplier's data hasn't been filled out correctly. As long as this error persist, the supplier can't be (correctly) synced to CASH. `}<inlineCode parentName="p">{`moet X @ bevatten 120=onbekend`}</inlineCode>{` in particular points to the email address being incorrect.`}</p>
    <p>{`Request Trustanbouw to enter the correct data.`}</p>
    <h4 {...{
      "id": "payment-reference-already-known"
    }}>{`payment reference already known`}</h4>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`Betaalkenmerk al gebruikt in boekstuk`}</inlineCode></p>
    </blockquote>
    <p>{`Whenever this message shows, it means that a purchase invoice
with the same number (supplierreference) already exists. The purchase invoice on which this number exists is shown
in the error message (journalnumber / stuknummer = 123432)`}</p>
    <p><img alt="Alt text" src={require("./blob/trustanbouw-payment-reference-already-known.png")} /></p>
    <p>{`We can fix this by updating the paymentreference (supplierreference).
1. Go to `}<inlineCode parentName="p">{`Correcties - inkoopfacturatie`}</inlineCode>{`
2. Search the purchase invoice by using the reference number`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Select the purchase invoice on which the journalnumber / stuknummer is empty.
`}<img alt="Alt text" src={require("./blob/trustanbouw-payment-reference-already-known-1.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Update the reference number with a trailing 1 (or 2, or 3)
`}<img alt="Alt text" src={require("./blob/trustanbouw-payment-reference-already-known-2.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Verify the changes and press save
`}<img alt="Alt text" src={require("./blob/trustanbouw-payment-reference-already-known-3.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wait until the change is propagated, then click on the red cross and re-sync the purchase invoice.
`}<img alt="Alt text" src={require("./blob/trustanbouw-payment-reference-already-known-4.png")} /></p>
      </li>
    </ol>
    <h4 {...{
      "id": "invoice-could-not-be-re-synced"
    }}>{`invoice could not be re-synced`}</h4>
    <p>{`This error alert can appear when you try to re-sync data from BPO (by clicking the red cross).`}</p>
    <p>{`When this happens, open your DevTools and check the response of your network call. It'll likely be this message:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "isValid":false,
  "messages":{
    "PurchaseInvoiceNotFound":"Invoice with id X not found."
  },
  "statusCode":404
}
`}</code></pre>
    <p>{`Trustanbouw sometimes splits up invoices into multiple invoices. This means that one invoice is deemed the 'parent invoice' and all child invoices gain a `}<inlineCode parentName="p">{`parentId`}</inlineCode>{` relating to that invoice. When you get the error shown above, you have a child invoice with a parent that does not exist, which is not allowed to happen.`}</p>
    <p>{`To figure out whether this is true, do the following:`}</p>
    <ol>
      <li parentName="ol">{`Connect to the Cash Api database (connectionstring can be found in the helm chart yaml)`}</li>
      <li parentName="ol">{`Query the `}<inlineCode parentName="li">{`"PurchaseInvoices"`}</inlineCode>{` table and search `}<inlineCode parentName="li">{`WHERE "Id" = '[ID]'`}</inlineCode>{`, replacing `}<inlineCode parentName="li">{`[ID]`}</inlineCode>{` with the id of the error.`}</li>
      <li parentName="ol">{`Find the linked `}<inlineCode parentName="li">{`parentId`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`In the Purchase Invoicing Api database, query the `}<inlineCode parentName="li">{`"Invoices"`}</inlineCode>{` table and search `}<inlineCode parentName="li">{`WHERE "Id" = '[PARENT_ID]'`}</inlineCode>{`, replacing `}<inlineCode parentName="li">{`[PARENT_ID]`}</inlineCode>{` with the `}<inlineCode parentName="li">{`parentId`}</inlineCode>{`.`}</li>
    </ol>
    <p>{`If the parent does not exist, check using the reference code of the invoice (from Data - Inkoopfacturen) if any other invoices exist relating to this one. If yes, update its `}<inlineCode parentName="p">{`parentId`}</inlineCode>{`.`}</p>
    <p>{`If the parent exists, check whether it is connected to a reference code. Ask Trustanbouw to which project / invoice this lost child invoice belongs.`}</p>
    <h3 {...{
      "id": "split-invoice-approved-and-booked-but-not-in-cash"
    }}>{`Split invoice approved and booked but not in CASH`}</h3>
    <p>{`Invoices that have been split gain a parentInvoice and all children of that invoice gain a parentId (explained above).`}</p>
    <p>{`All children of the parent invoice need to be approved before the whole invoice is to be sent to CASH.`}</p>
    <h3 {...{
      "id": "resending-an-invoice"
    }}>{`Resending an invoice`}</h3>
    <p>{`Invoices that have had an error or have been approved but aren't in CASH sometimes need to be offered to CASH again.`}</p>
    <p>{`To do so:
1. Find the InvoiceStatement within the CashIntegrationService.
2. Inspect the problem.
3. Clear the ExportFailureId and the ExportedOn fields.
4. The system should resend the InvoiceStatements within 15 seconds.`}</p>
    <h2 {...{
      "id": "bulk-importing-data"
    }}>{`Bulk importing data`}</h2>
    <p>{`The repo comes with a folder called 'utilities' (`}<inlineCode parentName="p">{`trustan\\utilities`}</inlineCode>{`) which holds a number of standalone importers. These importers are currently unavailable for the customer, though they may ask us to import a list of items for them as individual insertion from BPO can be a tedious and error-prone task.`}</p>
    <p>{`These importers require a `}<inlineCode parentName="p">{`.csv`}</inlineCode>{`. If the customer provides an Excel, either:`}</p>
    <ul>
      <li parentName="ul">{`Ask them to export it as a `}<em parentName="li">{`'puntkomma gescheiden bestand'`}</em>{`.`}</li>
      <li parentName="ul">{`Convert it yourself using Google Docs. First, unset all number, percentage and/or currency related settings so you'll get the raw data. Then export it as a comma-separated file. Finally, update all comma's to dot-comma's and remove any doublequotes (`}<inlineCode parentName="li">{`"`}</inlineCode>{`) around values.`}</li>
    </ul>
    <p>{`You may have to do some work to map it to the correct fields and with the correct values. The customer should have a template with the correct order of columns. If not, send it to them. The importers come provided with examples of what the values should be.`}</p>
    <p><inlineCode parentName="p">{`Trustanbouw.Portal.CrmImporter`}</inlineCode>{` has the following importers:`}</p>
    <ul>
      <li parentName="ul">{`Customers`}</li>
      <li parentName="ul">{`Disciplines`}</li>
      <li parentName="ul">{`Employees`}</li>
      <li parentName="ul">{`Projects`}</li>
      <li parentName="ul">{`Suppliers`}</li>
      <li parentName="ul">{`Workcodes`}</li>
    </ul>
    <p>{`In the `}<inlineCode parentName="p">{`csvs`}</inlineCode>{` folder you can find a number of working examples for the importers. For workcodes specifically, use `}<inlineCode parentName="p">{`202305-werkcodes-import.csv`}</inlineCode>{` as the baseline example for mapping.`}</p>
    <h2 {...{
      "id": "invoices-mailbox"
    }}>{`Invoices mailbox`}</h2>
    <p>{`Our current invoice mailbox user is hybrittest@trustanbouw.onmicrosoft.com (`}<a parentName="p" {...{
        "href": "https://outlook.office.com"
      }}>{`https://outlook.office.com`}</a>{`), credentials can be found in LastPass`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      